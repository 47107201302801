import {CONFIG} from "./app.config";
import {APP_INITIALIZER_BROWSER} from "@common/core/app-initializer/browser";
import {CONFIG_CORE_BROWSER} from "@common/core/config/browser";
import {APP_INITIALIZER, isDevMode} from "@angular/core";
import {ActiveOrdersService} from "@core/service/active-orders/active-orders.service";
import {provideServiceWorker} from "@angular/service-worker";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideAngularSvgIcon, SvgLoader} from "angular-svg-icon";
import {svgLoaderBrowserFactory} from "@core/service/svg-icon/svg-browser-factory.loader";
import {HttpClient} from "@angular/common/http";

const disableAnimations =
    !("animate" in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

/**
 * Конфиг только для браузерной версии сайта
 */
export const CONFIG_BROWSER = [
    ...CONFIG,
    ...APP_INITIALIZER_BROWSER,
    ...CONFIG_CORE_BROWSER,
    {
        provide: APP_INITIALIZER,
        useFactory: (activeOrdersService: ActiveOrdersService) => () => activeOrdersService.load(),
        deps: [ActiveOrdersService],
        multi: true,
    },
    provideAnimationsAsync(disableAnimations ? "noop" : "animations"),
    provideServiceWorker("ngsw-worker.js", {
        enabled: !isDevMode(),
        registrationStrategy: "registerWhenStable:30000",
    }),
    provideAngularSvgIcon({
        loader: {
            provide: SvgLoader,
            useFactory: svgLoaderBrowserFactory,
            deps: [HttpClient],
        },
    }),
];

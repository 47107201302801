import {afterNextRender, Component, inject, OnDestroy, OnInit} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";
import {AutorunService} from "@core/service/autorun/autorun.service";
import {AppService} from "./app.service";
import {OrdersDialogService} from "@shared/service/api/orders/dialog";
import {RouteService} from "@shared/service/route/route.service";
import {LazyLoadedService} from "@core/service/lazy-loaded/lazy-loaded.service";
import {Dialog} from "@angular/cdk/dialog";
import {DialogActiveOrdersDesktop, DialogActiveOrdersMobile} from "@shared/module_v2/dialog/ui/active-orders/active-orders";
import {Resize2Service} from "@core/service/resize2/resize2.service";
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from "@angular/router";
import {filter, take} from "rxjs/operators";
import {LoadingCommonState} from "@state/loading-common.state";
import {ActiveOrdersService} from "@core/service/active-orders/active-orders.service";
import {toObservable} from "@angular/core/rxjs-interop";
import * as Sentry from "@sentry/angular";
import {DeviceTokenService} from "@core/service/device-token/device-token.service";
import {Meta} from "@angular/platform-browser";
import {MobileService} from "@core/service/mobile";
import {DIALOG_UI_WARNING_RECIPE_CONFIG} from "@common/shared/component/dialog/ui/warning/warning";
import {BasketService} from "@common/core/service/basket";
import {Subscription} from "rxjs";
import {LoadingCommonComponent} from "@common/shared/component/loading/common/common.component";

@Component({
    standalone: true,
    selector: "app-megapteka",
    styleUrls: ["app.component.less"],
    templateUrl: "./app.component.html",
    imports: [LoadingCommonComponent, RouterOutlet],
    providers: [AppService, OrdersDialogService, RouteService, LazyLoadedService],
})
export class AppComponent implements OnInit, OnDestroy {
    private dialog = inject(Dialog);
    private router = inject(Router);
    private loadingCommonState = inject(LoadingCommonState);
    private activeOrdersService = inject(ActiveOrdersService);
    private deviceTokenService = inject(DeviceTokenService);
    private meta = inject(Meta);
    private mobileService = inject(MobileService);
    private basketService = inject(BasketService);
    private listActiveOrders = toObservable(this.activeOrdersService.list);

    private routerEvents = new Subscription();
    private basketServiceIsFullEvent = new Subscription();

    constructor(
        private _platform: PlatformService,
        private _autorunService: AutorunService,
        private _resizeService: Resize2Service,
    ) {
        this._autorunService.onInit();
        afterNextRender(() => {
            /**
             * Установка мета тега viewport после загрузки страницы
             * Нужно, что-бы клиенты могли зумить страницу. Если сразу вставлять, то хром увеличить сразу страницу.
             * Так как раньше width было 320px, и он походу храниться кэши хрома.
             * Это такой костыль для сброса этого кэша.
             */
            setTimeout(() => {
                let content = "width=device-width, initial-scale=1, maximum-scale=1.3";
                if (this.mobileService.isApple) {
                    content = "width=device-width, initial-scale=1, maximum-scale=1";
                }
                this.meta.updateTag({
                    name: "viewport",
                    content: content,
                });
            }, 4000);

            /**
             * Запуск лоудера при переходе по роутам
             */
            this.routerEvents = this.router.events
                .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
                .subscribe((event) => {
                    if (event instanceof NavigationStart) {
                        this.loadingCommonState.start();
                    }
                    if (event instanceof NavigationEnd) {
                        this.loadingCommonState.stop();
                    }
                });

            /**
             * Алерт если корзина полная.
             */
            this.basketServiceIsFullEvent = this.basketService
                .isFullEvent()
                .pipe(filter((res) => res === true))
                .subscribe(() => {
                    import("@common/shared/component/dialog/ui/warning/warning.component").then((res) => {
                        const options = DIALOG_UI_WARNING_RECIPE_CONFIG;
                        options.data = {
                            warning: {
                                code: "basket_max_count",
                                title: "Внимание",
                                text: "<b>Вы уже добавили максимальное количество товаров в корзину.</b> Удалите лишние товары из корзины или добавьте товар в избранное, чтобы не потерять его.",
                                button: {
                                    text: "Понятно",
                                },
                            },
                        };
                        this.dialog.open(res.DialogUiWarningRecipeComponent, options);
                    });
                });
        });
    }

    ngOnInit() {
        Sentry.setTag("device-token", this.deviceTokenService.get());
        this.checkoutOrder();
    }

    ngOnDestroy() {
        this.routerEvents.unsubscribe();
        this.basketServiceIsFullEvent.unsubscribe();
    }

    /**
     * Проверка активных заказов
     * После 10000ms начинает проверку.
     */
    checkoutOrder() {
        if (this._platform.browser) {
            setTimeout(() => {
                if (
                    this.router.url.indexOf("/check") !== -1 ||
                    this.router.url.indexOf("/basket") !== -1 ||
                    this.router.url.indexOf("/lk/orders") !== -1
                ) {
                    return;
                }
                this.listActiveOrders.pipe(take(1)).subscribe((list) => {
                    if (list && list.length > 0) {
                        import("@shared/module_v2/dialog/ui/active-orders/active-orders.component").then((res) => {
                            let options = new DialogActiveOrdersDesktop().options;
                            if (this._resizeService.resize$.value.mobile) {
                                options = new DialogActiveOrdersMobile().options;
                            }

                            options.data = list;
                            this.dialog.open(res.DialogActiveOrdersComponent, options);
                        });
                    }
                });
            }, 10000);
        }
    }
}

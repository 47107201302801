<div class="footer" itemscope itemtype="https://schema.org/Organization">
    <meta itemprop="name" content="Мегаптека.ру" />
    <meta itemprop="url" content="https://megapteka.ru" />
    <meta itemprop="logo" content="https://megapteka.ru/assets/images/logo/logo_180.png" />
    <meta itemprop="email" content="welcome@megapteka.ru" />
    <div class="wrapper">
        <div class="grid">
            <div class="one">
                <app-help class="help font"></app-help>
                <app-social-networks itemprop="sameAs" class="social-networks font"></app-social-networks>
            </div>
            <div class="two">
                <app-footer-catalog class="catalog font"></app-footer-catalog>
            </div>
            <div class="three">
                <app-mobile-app class="mobile-app font"></app-mobile-app>
                <app-politics class="politics font"></app-politics>
            </div>
            <div class="four">
                <app-company class="company font"></app-company>
            </div>
            <div class="five">
                <app-company-information class="company-information font"></app-company-information>
            </div>
            <div class="six">
                <app-licenses class="licenses font"></app-licenses>
            </div>
            <app-help-mobile class="help-mobile font"></app-help-mobile>
            <div class="seven">
                <app-warning-contraindications class="warning"></app-warning-contraindications>
                <app-recommendation-technologies class="recommendation-technologies font"></app-recommendation-technologies>
            </div>
        </div>
    </div>
</div>
